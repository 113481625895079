import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useLocation } from '@reach/router'
import { StaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, keywords, title, image, schemaMarkup }) {
    const { pathname } = useLocation()
    return (
        <StaticQuery
            query={detailsQuery}
            render={ (data) => {
                const metaDescription =
          description || data.site.siteMetadata.description
                return (
                    <Helmet
                        htmlAttributes={{
                            lang,
                        }}
                        title={title}
                        titleTemplate={`%s | ${data.site.siteMetadata.title}`}
                        meta={[
                            {
                                name: 'description',
                                content: metaDescription,
                            },
                            {
                                property: 'og:title',
                                content: title,
                            },
                            {
                                property: 'og:url',
                                content:  `${data?.site?.siteMetadata?.siteUrl}${pathname}`,
                            },
                            {
                                property: 'og:description',
                                content: metaDescription,
                            },
                            {
                                property: 'og:type',
                                content: 'website',
                            },
                            {
                                name: 'twitter:card',
                                content: 'summary',
                            },
                            {
                                name: 'twitter:creator',
                                content: data.site.siteMetadata.author,
                            },
                            {
                                name: 'twitter:title',
                                content: title,
                            },
                            {
                                name: 'twitter:description',
                                content: metaDescription,
                            },
                        ]
                            .concat(
                                keywords.length > 0
                                    ? {
                                        name: 'keywords',
                                        content: keywords.join(', '),
                                    }
                                    : []
                            )
                            .concat(
                                image
                                    ? [
                                        {
                                            property: 'og:image',
                                            content: image,
                                        },
                                        {
                                            property: 'twitter:image',
                                            content: image,
                                        },
                                        {
                                            name: 'twitter:card',
                                            content: 'summary_large_image',
                                        },
                                    ]
                                    : [
                                        {
                                            name: 'twitter:card',
                                            content: 'summary',
                                        },
                                    ])
                            .concat(meta)}
                    >
                        {schemaMarkup && 
                        <script type='application/ld+json'>{JSON.stringify(schemaMarkup)}</script>}
                    </Helmet>
                )
            }}
        />
    )
}

SEO.defaultProps = {
    lang: 'en',
    meta: [],
    keywords: [],
    image: 'https://res.cloudinary.com/dqpij5loy/image/upload/c_scale,w_400/v1606961295/Website_Desktop_Banner_Pink_BG_3840_x_2160px_itikmp.png',
    description: 'Four Words helps you to create the perfect ring with lab grown diamonds & gemstones. Learn how you can choose something that is more sustainable, higher quality & more cost effective.'
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
    schemaMarkup: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ])
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author,
        siteUrl
      }
    }
  }
`
