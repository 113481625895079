import React from 'react'
import PropTypes from 'prop-types'

const DiamondDB_URL = (size, shape, length, width, depth) => `https://www.diamdb.com/diamond/${size}ct-${shape}-${length}x${width}x${depth}/`

class CardComponent extends React.Component {
    constructor(props){
        super(props)    
        this.state = {
            show: false
        }
    }

    generateReportLink = (lab, report) => {
        if (lab == 'GIA')
            return `https://www.gia.edu/report-check?reportno=${report}`

        return `https://api.igi.org/viewpdf.php?r=${report}`
    }

    lwRatioCalculator = (length, width) => {
        let l = parseFloat(length)
        let w = parseFloat(width)
        if (l < w){
            return w/l
        }

        return l/w
    }

    tableDepthComparison = (table, depth) => {
        let t = Number(table)
        let d = Number(depth)

        if (t<=d) {
            return '✅'
        }
    }

    formatStoneGrade = (grade) => {
        switch (grade) {
            case 'Ideal': {
                return 'ID'
            }
            case 'Excellent': {
                return 'EX'
            }
            case 'Very Good': {
                return 'VG'
            }
            case 'Good': {
                return 'GD'
            }
            default: {
                return grade
            }
        }
    }

    __renderGroupLabel = (label, value) => {
        return <div className='fw-diamond-card__label pr-2'>
            <div className='fw-diamond-card__label'>{label}</div>
            <div className='fw-diamond-card__label --primary'>{this.formatStoneGrade(value)}</div>
        </div>
    }

    __renderCardHeader = () => {
        return (
            <figure className='fw-diamond-card__header'>
                {this.props.image && <img className='fw-diamond-card__image' src={this.props.image} width={192} height={192} alt={'Diamond Photo'}/>}
                <a className='fw-diamond-card__chip' href={this.props.video} target='_blank' rel='noopener noreferrer'>View Video</a>                
            </figure>
        )
    }

    __renderModal = () => {
        return <div>
            {this.props.supplierName}
            {this.props.supplierContactEmail}
            <code>
                {this.props.supplierLocationDetails}
            </code>
            <button className='btn btn-primary' onClick={() => this.setState({show: false})}>Close Me</button>
        </div>
    } 

    __renderCardBody = () => {
        return (
            <section className='fw-diamond-card__body'>
                <div className='d-flex justify-content-between align-items-center'>
                    <span className='fw-diamond-card__label'>#{this.props.stockId}</span>
                    <span className='fw-diamond-card__label' onClick={() => this.setState({show: !this.state.show ? true : false})}>{this.props.supplierName}</span>
                </div>
                <h2 className='fw-diamond-card__title'>{this.props.title}</h2>
                <p className='fw-diamond-card__title'>
                    <span>${Number.parseFloat(this.props.price).toFixed(2)}</span>
                    &nbsp;
                    <span className='fw-diamond-card__label'>(${this.props.pricePerCt} per/ct)</span>
                </p>
                <div className='fw-diamond-card__label'>
                    <span>{this.props.length} x {this.props.width} x {this.props.depth}mm</span>
                    &nbsp;&nbsp;
                    <a target='_blank' className='d-flex-inline align-items-center' rel='noopener noreferrer' href={`${DiamondDB_URL(this.props.stoneSize, this.props.stoneShape, this.props.length, this.props.width, this.props.depth)} `}>
                        <img src='https://www.diamdb.com/favicon-32x32.png?v=5a' width='14' height='14' style={{marginBottom: '0.15rem'}}/>&nbsp;Check DDB
                    </a>
                </div>

                <div className='fw-diamond-card__label'>
                    {Number.parseFloat(this.props.depthPercentage)}% Depth • {Number.parseFloat(this.props.tablePercentage)}% Table
                    {
                        this.props.stoneShape == 'princess' || this.props.stoneShape == 'oval' &&
                      <>
                          {this.tableDepthComparison(this.props.tablePercentage, this.props.depthPercentage)}
                      </>
                    }
                    &nbsp;•&nbsp;
                    {
                        this.props.stoneShape == 'round'  && 
                        <span>
                        ({this.props.crownAngle}° • {this.props.pavilionAngle}°)
                        </span>
                    }
                    {
                        this.props.stoneShape == 'princess' &&
                        <>
                            <span>
                                {(this.props.crownHeight >= 9.5 && this.props.crownHeight <= 14) && '✅'}
                            </span>
                        </>

                    }
                </div>

                <p className='fw-diamond-card__label'>
                    {
                        Number.parseFloat(this.lwRatioCalculator(this.props.length, this.props.width, this.props.id)).toFixed(2)
                    }
                    &nbsp; L/W Ratio
                </p>

                <div className='fw-diamond-card__section d-flex justify-content-between'>
                    <div className='fw-diamond-card__label pr-3'>
                        <div>Report No.</div>
                        <div><a className='--underline' href={this.generateReportLink(this.props.reportLab, this.props.reportNumber)} target='_blank' rel='noopener noreferrer'>{this.props.reportNumber}</a></div>
                    </div>
                    <div className='d-flex'>
                        {this.__renderGroupLabel('Cut', this.props.cut)}
                        {this.__renderGroupLabel('Polish', this.props.polish)}
                        {this.__renderGroupLabel('Symmetry', this.props.symmetry)}
                    </div>
                </div>
            </section>
        )
    }

    render () {
        return <div className='fw-diamond-card'>
            {this.__renderCardHeader()}
            {this.__renderCardBody()}
            <div className='fw-diamond-card__footer'>
                <hr className='my-2'/>
                <button className='fw-diamond-card__action btn btn-link' onClick={this.props.addToAirtable}>Add to Airtable</button>
            </div>
            {this.state.show && this.__renderModal()}
        </div>
    }
}

export default CardComponent

CardComponent.propTypes = {
    image: PropTypes.string,
    id: PropTypes.number,
    video: PropTypes.string,
    stockId: PropTypes.string,
    supplierName: PropTypes.string,
    stoneShape: PropTypes.string,
    stoneSize: PropTypes.string,
    title: PropTypes.string,
    reportLab: PropTypes.string,
    reportNumber: PropTypes.string,
    cut: PropTypes.string,
    polish: PropTypes.string,
    symmetry: PropTypes.string,
    price: PropTypes.string,
    pricePerCt: PropTypes.string,
    length: PropTypes.string,
    width: PropTypes.string,
    depth: PropTypes.string, 
    depthPercentage: PropTypes.string,
    tablePercentage: PropTypes.string,
    ratio: PropTypes.string,
    supplierContactEmail: PropTypes.string,
    supplierLocationDetails: PropTypes.object,
    addToAirtable: PropTypes.func,
    crownAngle: PropTypes.string,
    pavilionAngle: PropTypes.string,
    crownHeight: PropTypes.string
}